





















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import GiveCouponAPIClass from "@/common/api/giveCoupon";
export const pageApiList = getPageAPIs(GiveCouponAPIClass);
@Component({})
export default class UserList extends Vue {
  private buyAddressApi: any = new GiveCouponAPIClass();
  private sellShow: boolean = false;
  private forms: any = {};
  private form: any = {
    time: [], // 时间选择
  };

  private getQueryParams(url: string) {
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj
  }

  private get isShowByShopId(): boolean {
    const url = window.location.href
    if (url.indexOf('?') < 0) {
      return true
    }
    let params = this.getQueryParams(url);
    console.log('参数', params)
    let shopId: string = params.shop_id
    //url参数路由拼接错乱，例如http://192.168.0.17:8084/#/givecoupons?shop_id=3pk2#/givecoupons
    //出现了两个#，需要再做一步处理
    if (shopId.indexOf('#')) {
      shopId = shopId.split('#')[0]
    }
    const value: string = '3pk2' //花园道店id需要隐藏的
    if (this.$store.state.platformType === 1) {
      if (shopId == value) {
        return false
      }
    }
    return true

  }

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.buyAddressApi.voucherhistory(
        {
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.total;
          // this.tablePage.page = res.data.page;
          // this.tablePage.prepage = res.data.prepage;
        }
    );
  }

  private vouchergive() {
    this.voucherindex();
    this.restForm();
    this.sellShow = true;
  }

  private options: any = [];

  private voucherindex(): void {
    this.buyAddressApi.voucherindex(
        {
          page: 1,
          limit: 200,
        },
        (res: any) => {
          this.options = res.data.list;
        }
    );
  }

  private submit(): void {
    var reg_phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

    if (!reg_phone.test(this.forms.phone)) {
      Util.showToast("请输入正确的手机号", "warning");
      return;
    }
    this.buyAddressApi.vouchergive(this.forms, () => {
      this.sellShow = false;
      Util.showToast("赠送成功");
      this.search();
    });
  }

  private restForm(): void {
    this.forms = new Util().clearObject(this.forms);
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
