import {BasicsClass} from "@/common/BasicsClass";

export default class GiveCouponAPIClass extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/voucher/index',
            name: 'list',
            label: '优惠券列表'
        }],
        ['history', {
            url: '/voucher/history',
            name: 'history',
            label: '赠送记录'
        }],
        ['give', {
            url: '/voucher/give',
            name: 'give',
            label: '赠送'
        }],
    ])


    public voucherindex(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getList').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public voucherhistory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('history').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public vouchergive(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('give').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
